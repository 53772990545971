var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "direct-flight-select mb-1 justify-content-between ml-1"
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "disabled": _vm.isDisabled,
      "switch": ""
    },
    on: {
      "input": function input(val) {
        return _vm.handleUpdate(val);
      }
    },
    model: {
      value: _vm.isSelected,
      callback: function callback($$v) {
        _vm.isSelected = $$v;
      },
      expression: "isSelected"
    }
  }, [_c('span', {
    class: "text-nowrap text-body font-weight-bold ".concat(_vm.isDisabled ? 'text-muted' : '')
  }, [_vm._v(_vm._s(_vm.$t('flight.monthlyCheapestFare')))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }